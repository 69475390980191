exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-book-demo-js": () => import("./../../../src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contactus-en-au-js": () => import("./../../../src/pages/contactus.en-au.js" /* webpackChunkName: "component---src-pages-contactus-en-au-js" */),
  "component---src-pages-contactus-en-in-js": () => import("./../../../src/pages/contactus.en-in.js" /* webpackChunkName: "component---src-pages-contactus-en-in-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-features-pos-hardware-js": () => import("./../../../src/pages/features/pos-hardware.js" /* webpackChunkName: "component---src-pages-features-pos-hardware-js" */),
  "component---src-pages-index-en-au-js": () => import("./../../../src/pages/index.en-au.js" /* webpackChunkName: "component---src-pages-index-en-au-js" */),
  "component---src-pages-index-en-in-js": () => import("./../../../src/pages/index.en-in.js" /* webpackChunkName: "component---src-pages-index-en-in-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-and-conditions-js": () => import("./../../../src/pages/legal/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-js" */),
  "component---src-pages-partners-integrate-with-us-en-au-js": () => import("./../../../src/pages/partners/integrate-with-us.en-au.js" /* webpackChunkName: "component---src-pages-partners-integrate-with-us-en-au-js" */),
  "component---src-pages-partners-integrate-with-us-en-in-js": () => import("./../../../src/pages/partners/integrate-with-us.en-in.js" /* webpackChunkName: "component---src-pages-partners-integrate-with-us-en-in-js" */),
  "component---src-pages-partners-integrate-with-us-js": () => import("./../../../src/pages/partners/integrate-with-us.js" /* webpackChunkName: "component---src-pages-partners-integrate-with-us-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

