import React, { useState, useEffect } from "react"
const defaultState = {
  defaultValue: "GBP",
}
const CurrencyContext = React.createContext(defaultState)
const isBrowser = () => typeof window !== "undefined"
const ThemeContext = ({ children }) => {
  const [activeState, setActiveState] = useState(() => {
    const localData = isBrowser() && window.localStorage.getItem("activeState")
    return localData ? JSON.parse(localData) : { defaultValue: "GBP" }
  })
  useEffect(() => {
    window.localStorage.setItem("activeState", JSON.stringify(activeState))
  }, [activeState])
  return (
    <CurrencyContext.Provider
      value={{
        defaultValue: activeState.defaultValue,
        chooseMe: (e) =>
          setActiveState({
            defaultValue: e.value,
          }),
      }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}
export default CurrencyContext
export { ThemeContext }
